import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { setCredentials } from "./reducers/authSlice";
import { useAppDispatch } from "./hooks/store";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS?.token);
    console.log(token, "=========");
    if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      // navigate(window?.location?.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Routing />;
}

export default App;
