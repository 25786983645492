import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [legal, setLegal] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Terms and Conditions" {...a11yProps(1)} />
              <Tab label="Notification" {...a11yProps(2)} />
              <Tab label="Account" {...a11yProps(3)} />
              <Tab label="Raise Query" {...a11yProps(4)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Card sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <Typography component="p">admin@yopmail.com</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <Typography component="p">0 (000) 000-000</Typography>
                  </Grid>
                </Grid>
                {/* <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div> */}
              </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Card sx={{ p: 3 }}>
                {/* <Typography className="heading1">Terms & Condition</Typography> */}
                <Typography component="p">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Typography>
              </Card>
              {/* <form>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form> */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Card className="notifi">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="flexDiv">
                    <Typography className="custom_label">
                      Broadcast notification
                    </Typography>
                    <Switch size="small" />
                  </Grid>
                  <Grid item xs={12} className="flexDiv">
                    <Typography className="custom_label">Email</Typography>
                    <Switch size="small" />
                  </Grid>
                  <Grid item xs={12} className="flexDiv">
                    <Typography className="custom_label">Ratings</Typography>
                    <Switch size="small" />
                  </Grid>
                  <Grid item xs={12} className="flexDiv">
                    <Typography className="custom_label">Earnings</Typography>
                    <Switch size="small" />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Card className="notifi">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="flexDiv">
                    <Typography className="custom_label">
                      <PersonOutlineOutlinedIcon /> <span>Account Status</span>
                    </Typography>
                    <Switch size="small" />
                  </Grid>
                </Grid>
              </Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Card sx={{ p: 3 }}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Add Query
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="query"
                        variant="outlined"
                        fullWidth
                        placeholder="Query"
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Card>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
