import LoginPage from "./auth/login";
import SignUpPage from "./auth/signUp";
import DashBoardPage from "./dashBoard";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import Settings from "./settings";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ChangePassword from "./auth/changePassword";
import ManageReview from "./review";
import ReviewDetails from "./review/details";
import ProfileSetup from "./profileSetup";
import ManageTransactions from "./manageTransactions";
import TransactionDetails from "./manageTransactions/details";
import ManageWallet from "./manageWallet";
import WalletDetails from "./manageWallet/details";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  SignUpPage,
  DashBoardPage,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  Settings,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  RecievedNotifications,
  ChangePassword,
  ManageReview,
  ReviewDetails,
  ProfileSetup,
  ManageTransactions,
  TransactionDetails,
  ManageWallet,
  WalletDetails,
};
