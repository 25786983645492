import { useState } from "react";

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostSignUpMutation } from "../../services/auth";
import { Loader, showError } from "../../constants";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [signUpMutation, { isLoading }] = usePostSignUpMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPass: "",
    },

    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string().required("Password is required"),
      confirmPass: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "password should be match"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        password: formik.values.password,
      };

      try {
        const response = await signUpMutation(body).unwrap();
        if (response?.status === 201) {
          const data1 = {
            key: body?.email,
            page: "signUp",
          };
          console.log("success");
          navigate("/verify-otp", { state: data1 });
        } else {
          showError(response?.message);
        }
      } catch (error) {
        // showError(error?.message);
        console.log(error);
      }
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box bg_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">Create an account</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%", zIndex: 1 }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                className="text_field"
                type="email"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="password"
                className="text_field"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Confirm Password</Typography>
              <TextField
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="confirmPass"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                className="text_field"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPass}
                helperText={
                  formik.touched.confirmPass && formik.errors.confirmPass
                }
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // onClick={() => navigate("/verify-otp", { state: "signup" })}
              >
                Sign Up
              </Button>
            </Box>
          </form>

          <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
            <Typography className="custom_label">
              Already have an account?
            </Typography>
            <Typography
              className="custom_label login_txt"
              onClick={() => navigate("/")}
            >
              Login
            </Typography>
          </Box>
        </div>
      </Container>
    </Box>
  );
};

export default SignUp;
