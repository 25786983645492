import { Routes, Route } from "react-router-dom";
import Pages from "./pages";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="/sign-up" element={<Pages.SignUpPage />} />
      <Route path="forgot-password" element={<Pages.ForgotPasswordPage />} />
      <Route path="verify-otp" element={<Pages.VerifyOtp />} />
      <Route path="reset-password" element={<Pages.ResetPassword />} />
      <Route path="change-password" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/profile-setup" element={<Pages.ProfileSetup />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/settings" element={<Pages.ManageCms />} />
      <Route path="/manage-review" element={<Pages.ManageReview />} />
      <Route path="/manage-wallet" element={<Pages.ManageWallet />} />
      <Route path="/manage-wallet/details" element={<Pages.WalletDetails />} />
      <Route
        path="/manage-transactions"
        element={<Pages.ManageTransactions />}
      />
      <Route
        path="/manage-transactions/details"
        element={<Pages.TransactionDetails />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route path="/manage-review/details" element={<Pages.ReviewDetails />} />
    </Routes>
  );
};

export default Routing;
