import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import { usePostVerifyOtpMutation } from "../../../services/auth";
import { useFormik } from "formik";
import { STORAGE_KEYS, setToStorage, showError } from "../../../constants";
import { useAppDispatch } from "../../../hooks/store";
import { setCredentials } from "../../../reducers/authSlice";
import { usePostForgotPasswordMutation } from "../../../services/auth";
import { showToast } from "../../../constants";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const location = useLocation();
  const state = location;
  console.log(state, "newState");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [postForgotPassowrd] = usePostForgotPasswordMutation();
  const [countDown, setCountDown] = useState<number>(59);

  const [verifyOtp, { isLoading }] = usePostVerifyOtpMutation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission
    console.log(typeof otp);
    setLoading(true);
    if (otp?.length === 4) {
      setError(false);
      const data = {
        email:
          state?.state?.page === "signUp"
            ? state?.state?.key
            : state?.state?.email,
        otp: String(otp),
      };

      try {
        const response: any = await verifyOtp(data).unwrap();
        if (response?.statusCode === 200 || response?.code === 200) {
          setLoading(false);
          setToStorage(STORAGE_KEYS.token, response?.token || "");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.token || null,
            })
          );
          if (state?.state?.page === "signUp") {
            navigate("/profile-setup");
          } else if (state?.state?.page === "login") {
            navigate("/reset-password", { state: state });
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    let data = {
      email: state?.state?.key,
      // otp:otp
    };
    try {
      const response = await postForgotPassowrd(data).unwrap();
      if (response?.status === 200 || response?.code === 200) {
        setLoading(false);
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => {
                if (state?.state?.page === "signUp") {
                  navigate("/sign-up");
                } else if (state?.state?.page === "login") {
                  navigate("/forgot-password");
                }
              }}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Verify Otp
            </Typography>
          </Box>
          {/* <form>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // onClick={() => {
                //   if (state?.state === "signup") {
                //     navigate("/profile-setup");
                //   } else {
                //     navigate("/reset-password");
                //   }
                // }}
              >
                Verify Otp
              </Button>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography className="anchor_link" sx={{ cursor: "pointer" }}>
                Resend Otp
              </Typography>
            </Box>
          </form> */}
          <form onSubmit={handleSubmit}>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
              {error && otp.length !== 4 ? (
                <h6
                  className="err_msg"
                  style={{ textAlign: "center", paddingTop: "10px" }}
                >
                  Please enter the OTP
                </h6>
              ) : (
                ""
              )}
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Verify Otp
              </Button>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              {countDown === 0 ? (
                <Typography
                  className="anchor_link"
                  sx={{
                    cursor: "pointer",
                    textAlign: "center",
                    paddingTop: "10px",
                  }}
                  onClick={handleResendOtp}
                >
                  Resend Otp
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "grey",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  The verification code will expire in{" "}
                  <p style={{ margin: 0, color: "#D63853", paddingLeft: 3 }}>
                    00 : {countDown < 10 ? `0${countDown}` : countDown}
                  </p>
                </Typography>
              )}
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
