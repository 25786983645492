import { END_POINTS } from "../constants/url";
import { CategoryData } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  status: number;
  message: string;
  code: number;
};

type PostAuthBody = {
  email?: string;
  password?: string;
};

type PostLoginBody = {
  email?: string;
  password?: string;
  // phone?: string;
  // countryCode?: string;
  // countryName?: string;
};
type PostForgotPasswordBody = {
  email: string;
};

type PostVerifyOtpBody = {
  // type?: string;
  // key: string;
  // code: string;
  email?: string;
  otp?: string;
};
type PostResetPassword = {
  email: string;
  password: string;
};

type LinkBody = {
  plaid_link_token: string;
};

type UpdateProfileBody = {
  user_details: {
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_no: string;
    profile_picture: number | null;
    vendor_category: string;
  };

  documents: any[];
  // documents: [{
  //   id: number,
  //   front_side: number,
  //   back_side: number,
  //   document_number: number,
  //   expiry_date: string
  // }]
};
type getCategoryBody = {
  length: number;
  start: number;
  search: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postSignUp: builder.mutation<
      CommonResponseType & { data: User },
      PostAuthBody
    >({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      PostLoginBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    getProfile: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.vendor,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation<
      { code: number; data: User; message: string },
      UpdateProfileBody
    >({
      query: (body) => ({
        url: END_POINTS.edit_vendor_profile,
        method: "PUT",
        body,
      }),
    }),
    // sendOtp: builder.mutation<CommonResponseType, PostForgotPasswordBody>({
    //   query: (body) => ({
    //     url: END_POINTS.sendOtp,
    //     method: "POST",
    //     body,
    //   }),
    // }),
    postVerifyOtp: builder.mutation<
      CommonResponseType & { data: User },
      PostVerifyOtpBody
    >({
      query: (body) => ({
        url: END_POINTS.verify_otp,
        method: "POST",
        body,
      }),
    }),
    postForgotPassword: builder.mutation<
      CommonResponseType & { data: User },
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postResetPass: builder.mutation<CommonResponseType, PostResetPassword>({
      query: (body) => ({
        url: END_POINTS.resetPass,
        method: "POST",
        body,
      }),
    }),

    getCategory: builder.mutation<
      CommonResponseType & { data: CategoryData[] },
      getCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.getAllCategory,
        method: "POST",
        body,
      }),
    }),

    getPlaidLink: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.tokenLink,
        method: "GET",
      }),
    }),

    postLinkToken: builder.mutation<CommonResponseType, LinkBody>({
      query: (body) => ({
        url: END_POINTS.getToken,
        method: "POST",
        body,
      }),
    }),

    getAllAccounts: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.getAllAccounts,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostSignUpMutation,
  usePostLoginMutation,
  useLazyGetProfileQuery,
  useEditProfileMutation,
  usePostVerifyOtpMutation,
  usePostForgotPasswordMutation,
  usePostResetPassMutation,
  useGetCategoryMutation,
  useLazyGetPlaidLinkQuery,
  usePostLinkTokenMutation,
  useLazyGetAllAccountsQuery,
} = authApi;
