import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReviewDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-orders");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Student Name</Typography>
                      <Typography component="p">Abdul67</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">abdul@yopmail.com</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone Number</Typography>
                      <Typography component="p">+91 78775645434</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Course Name</Typography>
                      <Typography component="p">food hub</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Course Price</Typography>
                      <Typography component="p">10,000</Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      // mb={3}
                    >
                      Student Review
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} pt={1}>
                    <Box>
                      <Typography component="p">
                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                        IpsumLorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                        Lorem Ipsum Lorem IpsumLorem Ipsum
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      // mb={3}
                    >
                      Add Comment
                    </Typography>
                  </Grid>
                  {/* <Grid container spacing={3}> */}
                  <Grid item lg={6} md={6} sm={12} xs={12} pt={1}>
                    <TextField
                      className="text_field"
                      // hiddenLabel
                      type={"text"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder="Type here"
                      multiline
                      rows={5}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} pt={1}>
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </CardContent>
        </Card>

        {/* <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Post For Sell" {...a11yProps(0)} />
              <Tab label="Post For Auction" {...a11yProps(1)} />
              <Tab label="Earnings" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <UserPostForSell></UserPostForSell>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <UserPostForAuction></UserPostForAuction>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <UserEarnings></UserEarnings>
            </CustomTabPanel>
          </Box>
        </Card> */}
      </div>
    </MainContainer>
  );
};

export default ReviewDetails;
