import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useState } from "react";
import { MainFaq } from "../../components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Faq = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const faqs = [
    { id: 1, ques: "Question1", ans: "Answer1" },
    { id: 2, ques: "Question2", ans: "Answer2" },
    { id: 3, ques: "Question3", ans: "Answer3" },
  ];

  return (
    <MainContainer>
      <div className="main_loyout ">
        <div className="dashboard">
          <h1 className="mn_hdng"> Faqs</h1>
        </div>
        <div>
          <Card className="cards">
            {/* <Box className="cards_header">
              <Box className="cards_header_right2">
                <Button
                  className="btn btn_primary"
                  // onClick={() => navigate("/manage-courses/add")}
                >
                  Add Faq
                </Button>
              </Box>
            </Box> */}
            <div className="faq_sc">
              {faqs?.length ? (
                faqs?.map((item) => (
                  // <Accordion key={item?.id} className="accordion_item">
                  //   <AccordionSummary
                  //     className="accordion_title"
                  //     aria-controls="panel1d-content"
                  //     id="panel1d-header"
                  //   >
                  //     {item?.ques || ""}
                  //   </AccordionSummary>
                  //   <AccordionDetails className="accordion_content">
                  //     <p>{item?.ans || ""}</p>
                  //   </AccordionDetails>
                  // </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{item?.ques || ""}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{item?.ans || ""}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <p>No faq's listing found</p>
              )}
            </div>

            {/* <Box className="custom_tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Budget " {...a11yProps(0)} />
                <Tab label="Goals " {...a11yProps(1)} />
                <Tab label="Round Up " {...a11yProps(2)} />
                <Tab label="Savings " {...a11yProps(3)} />
              </Tabs>
              <MainFaq />
            </Box> */}
          </Card>
        </div>
      </div>
    </MainContainer>
  );
};

export default Faq;
