export const API_URL = "https://apidevnarwhale.appgrowthcompany.com/api/"; //development

// export const APP_URL = "https://apistagingnarwhale.appgrowthcompany.com/api/";

export const END_POINTS = {
  signUp: "vendor/signup/",
  login: "vendor/signin/",
  vendor: "vendor/",
  edit_vendor_profile: "vendor/",
  verify_otp: "verify/otp/",
  forgotPassword: "admin/forgot-password/",
  resetPass: "admin/update-password/",
  getAllCategory: "admin/get-all-category/",
  upload: "upload/media/",
  tokenLink: "link-token-generation/",
  getToken: "exchange-link-token/",
  getAllAccounts: "user-accounts/",
};
