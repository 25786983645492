import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";

const ManageWallet = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStateDate = (event: any) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDate = (event: any) => {
    setEndDate(event.target.value as string);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Wallet</h1>
        </div>
        <Card sx={{ mt: 4 }} className="cards">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ pt: 2.3 }}>
              <SearchBar />
            </Box>
            <Box className="cards_header_right">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">Start Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validFrom"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder={"Start Date"}
                  onChange={(val) => handleStateDate(val)}
                  value={startDate}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validTill"
                  className="text_field"
                  variant="outlined"
                  disabled={!startDate}
                  fullWidth
                  placeholder={"End Date"}
                  onChange={(val) => handleEndDate(val)}
                  value={endDate}
                  inputProps={{
                    min: startDate,
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            </Box>
          </Box>
          <Box sx={{ pt: 2, display: "flex", gap: 2 }}>
            <Typography className="custom_label">
              Total Withdraw Amount : $ 20,000
            </Typography>
          </Box>
          <Box className="custom_tabs">
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Withdraw Amount</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Total Balance</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">$ 1000</TableCell>
                    <TableCell align="center">25/12/2003</TableCell>
                    <TableCell align="center">$ 20,000</TableCell>
                    <TableCell align="center">
                      <img
                        className="status_img"
                        src="/static/images/increase.png"
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <VisibilityIcon
                            onClick={() => navigate("/manage-wallet/details")}
                          />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{2}</TableCell>
                    <TableCell align="center">$ 1000</TableCell>
                    <TableCell align="center">25/12/2003</TableCell>
                    <TableCell align="center">$ 20,000</TableCell>
                    <TableCell align="center">
                      <img
                        className="status_img"
                        src="/static/images/decrease.png"
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <VisibilityIcon
                            onClick={() => navigate("/manage-wallet/details")}
                          />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageWallet;
