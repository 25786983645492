import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  useLazyGetAllAccountsQuery,
  useLazyGetPlaidLinkQuery,
  useLazyGetProfileQuery,
  usePostLinkTokenMutation,
} from "../../services/auth";
import { useEditProfileMutation } from "../../services/auth";
import * as Yup from "yup";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import { useAppSelector } from "../../hooks/store";
import { getToken } from "../../reducers/authSlice";
import { CategoryData } from "../../types/General";
import { useGetCategoryMutation } from "../../services/auth";
import { UploadMedia } from "../../utils/mediaUpload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PlaidLink } from "react-plaid-link";
import moment from "moment";

const ProfileSetup = () => {
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState("+965");
  const [getLinkToken] = usePostLinkTokenMutation();
  const [professionType, setProfessionType] = useState<string>("");
  const [getProfile] = useLazyGetProfileQuery();
  const [getTokenQuery] = useLazyGetPlaidLinkQuery();
  const [getUserAccounts] = useLazyGetAllAccountsQuery();
  const [editProfile, { isLoading }] = useEditProfileMutation();
  const [email, setEmail] = useState("");
  const [profile_picture, setProfilePicture] = useState(""); // main profile
  const [imageId, setImageId] = useState(0); // for main profile
  const token = useAppSelector(getToken);
  // const [category, setCategory] = useState<string>("");
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [getAllCate] = useGetCategoryMutation();
  const [front_side, setFrontSide] = useState(""); // front image
  const [back_side, setBackSide] = useState(""); // back image
  const [frontImageId, setFrontImageId] = useState(0); // for front image
  const [backImageId, setBackImageId] = useState(0); // for back image
  const [loading, setLoading] = useState(false);
  const [plaidToken, setPlaidToken] = useState("");
  const [linkToken, setLinkToken] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [error, setError] = useState(false);
  console.log(linkToken);

  const onSuccess = (token: string, metadata: any) => {
    console.log("Plaid Link success:", token, metadata);
    setLinkToken(token);
    // Send the public_token to your server
  };

  const getPlaidLink = async () => {
    try {
      const response = await getTokenQuery({}).unwrap();
      if (response?.status === 200) {
        // setCategoryData(response?.data || []);
        setPlaidToken(response?.data);
      } else {
        setPlaidToken("");
      }
    } catch (error) {
      console.error("error in fetching the data");
    }
  };

  const getAllUserAccounts = async () => {
    try {
      const response = await getUserAccounts({}).unwrap();
      if (response?.status === 200) {
        // setCategoryData(response?.data || []);
        setBankData(response?.data);
      } else {
        setPlaidToken("");
      }
    } catch (error) {
      console.error("error in fetching the data");
    }
  };

  // for image upload api

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
      if (res?.code === 200) {
        formik.setFieldValue("profile_picture", res?.data[0]?.media_file_url); // Set the value in Formik
        setProfilePicture(res?.data[0]?.media_file_url); // Update state
        setImageId(res?.data[0]?.id);
        // Store profile picture URL in local storage
        // localStorage.setItem("profile_picture", res?.data[0]?.media_file_url);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    }
  };

  // this is for front image handle
  const handleUploadFrontImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    console.log("llllllllllllllllllllllllllllllllllll");

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.code === 200) {
          setLoading(false);
          setFrontSide(res?.data[0]?.media_file_url);
          setFrontImageId(res?.data[0]?.id);
          // localStorage.setItem("profile_picture", res?.data[0]?.media_file_url);
        } else {
          showError(res?.message);
        }
      } else {
        setLoading(false);
        // Display an error message for non-image files
        showError("Invalid");
      }
    }
  };

  // this is for the handle the back image
  const handleUploadBackImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.code === 200) {
          setLoading(false);
          setBackSide(res?.data[0]?.media_file_url);
          setBackImageId(res?.data[0]?.id);
        } else {
          showError(res?.message);
        }
      } else {
        setLoading(false);
        // Display an error message for non-image files
        showError("Invalid");
      }
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // formik.setFieldValue("phone", country + phone);
    setPhoneCode(
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    // formik.setFieldValue(
    //   "countryCode",
    //   country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    // );
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: email || "",
      country_code: "",
      phone: "",
      profile_picture: 1,
      vendor_category: 1,
      front_side: 1,
      back_side: 1,
      // document: [{
      //   front_side: 1,
      //   back_side: 1
      // }],
      document_number: "",
      expiry_date: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("first name is required"),
      last_name: Yup.string().required("last name is required"),
      phone: Yup.string().required("phone is required"),
      document_number: Yup.number().required("Document number is required"),
      expiry_date: Yup.string().required("Expiry date is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      formik.setSubmitting(true);

      let body = {
        user_details: {
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          email: formik.values.email,
          country_code: phoneCode,
          phone_no: formik.values.phone,
          profile_picture: imageId || null,
          vendor_category: professionType,
          // Doc_number: formik.values.Doc_number,
        },
        // documents: [],
        documents: [
          {
            id: 0,
            front_side: frontImageId || null,
            back_side: backImageId || null,
            document_number: formik.values.document_number,
            expiry_date: formik.values.expiry_date,
          },
        ],
      };

      try {
        const response = await editProfile(body).unwrap();
        if (response?.code === 200) {
          showToast("Profile setup completed");
          navigate("/");
        } else {
          showError(response?.data?.phone_no[0]);
        }
      } catch (error) {
        console.log(error);
        console.error("error in fetching the api");
      }
    },
  });

  // category
  const getAllCategories = async () => {
    let body = {
      length: 50,
      start: 1,
      search: "",
    };
    try {
      const response = await getAllCate(body).unwrap();
      if (response?.code === 200) {
        setCategoryData(response?.data || []);
      } else {
        setCategoryData([]);
      }
    } catch (error) {
      console.error("error in fetching the data");
    }
  };
  useEffect(() => {
    if (token) {
      getAllCategories();
    }
  }, [token]);

  // vendors
  const getAllVendor = async () => {
    try {
      const response = await getProfile({}).unwrap();
      setEmail(response.data.email);
      formik.setFieldValue("email", response.data.email);
    } catch (error) {
      console.error("this is an error1");
    }
  };

  useEffect(() => {
    if (token) {
      getAllVendor();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getPlaidLink();
    }
  }, [token]);

  const handleSendToken = async () => {
    let body = {
      plaid_link_token: linkToken,
    };
    try {
      const response = await getLinkToken(body).unwrap();
      if (response?.status === 200) {
        showToast("Account linked successfully");
        setIsVisible(true);
        getAllUserAccounts();
      }
    } catch (error) {
      console.error("error in fetching the api");
    }
  };

  useEffect(() => {
    if (linkToken) {
      handleSendToken();
    }
  }, [linkToken]);

  const handleProfessionChange = (event: SelectChangeEvent) => {
    setProfessionType(event.target.value as string);
  };

  useEffect(() => {
    if (token) {
      getAllUserAccounts();
    }
  }, [token]);

  return (
    <div className="authh" style={{ margin: "60px" }}>
      <Loader isLoad={isLoading} />
      <div className="profile_wrap">
        <Card className="dashboard2">
          <h1>Profile</h1>
        </Card>
        <Card className="cards" style={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {profile_picture ? (
                    <div className="upload_image_preview">
                      <CardMedia
                        component="img"
                        image={profile_picture}
                        alt="photo"
                      />
                      <CancelIcon onClick={() => setProfilePicture("")} />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{ accept: "image/png,image/jpeg" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImageUpload(e)
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                {/* <Grid item xs={3}>
              <Typography className="custom_label">
                Account Status : Pending
              </Typography>
            </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">First Name</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="first_name"
                    variant="outlined"
                    fullWidth
                    placeholder="First Name"
                    value={formik.values.first_name}
                    onChange={(event) => {
                      const value = event.target.value;
                      // Check if the value contains only letters and has a maximum length of 25 characters
                      if (/^[a-zA-Z]*$/.test(value) && value.length <= 25) {
                        formik.setFieldValue("first_name", value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      (formik.touched.first_name && formik.errors.first_name) ||
                      ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Last Name</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="last_name"
                    variant="outlined"
                    fullWidth
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={(event) => {
                      const value = event.target.value;
                      // Check if the value contains only letters and has a maximum length of 25 characters
                      if (/^[a-zA-Z]*$/.test(value) && value.length <= 25) {
                        formik.setFieldValue("last_name", value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      (formik.touched.last_name && formik.errors.last_name) ||
                      ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    disabled
                  ></TextField>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    country={"us"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                    value={phoneCode + formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select Category
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={professionType}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleProfessionChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {categoryData?.length > 0
                        ? categoryData?.map((row) => (
                            <MenuItem value={row?.id}>
                              {row?.category_name}
                            </MenuItem>
                          ))
                        : " "}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12} />

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ mt: 2, pb: 2 }}
                >
                  {bankData?.length > 0 ? (
                    ""
                  ) : (
                    <PlaidLink
                      token={plaidToken}
                      onSuccess={onSuccess}
                      style={{
                        backgroundColor: "#2593bd",
                        padding: "0px 6px 0px 6px",
                        cursor: "pointer",
                      }}
                      // env={plaidToken}
                    >
                      <p style={{ color: "white", fontSize: "18px" }}>
                        Link your bank account
                      </p>
                    </PlaidLink>
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography className="custom_label">QR Code</Typography>
                    <img
                      src="/static/images/qr.png"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    Upload Document
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="custom_label">Front Image</Typography>

                  <div className="upload_document w_50">
                    {front_side ? (
                      <div className="inner">
                        <div className="cross_icn">
                          <CancelIcon
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setFrontSide("");
                            }}
                          />
                        </div>

                        <figure>
                          <LazyLoadImage
                            width={"100%"}
                            height={"100%"}
                            alt="photo"
                            src={front_side ? front_side : ""}
                            effect="blur"
                          />
                        </figure>
                      </div>
                    ) : (
                      <label htmlFor="icon-button-file-front">
                        <div className="inner">
                          <span>
                            <img src="static/images/icon_upload.svg" alt="" />
                          </span>
                          <Input
                            id="icon-button-file-front"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleUploadFrontImage(e)}
                          />
                        </div>

                        {error && !front_side ? (
                          <h6 className="err_msg">Front image is required</h6>
                        ) : null}
                      </label>
                    )}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="custom_label">Back Image</Typography>
                  <div className="upload_document w_50">
                    {back_side ? (
                      <div className="inner">
                        <div className="cross_icn">
                          <CancelIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setBackSide("");
                            }}
                          />
                        </div>

                        <figure>
                          <LazyLoadImage
                            width={"100%"}
                            height={"100%"}
                            alt="photo"
                            src={back_side ? back_side : ""}
                            effect="blur"
                          />
                        </figure>
                      </div>
                    ) : (
                      <label htmlFor="icon-button-file-back">
                        <div className="inner">
                          <span>
                            <img src="static/images/icon_upload.svg" alt="" />
                          </span>
                          <Input
                            id="icon-button-file-back"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleUploadBackImage(e)}
                          />
                        </div>

                        {/* {!back_side ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null} */}
                      </label>
                    )}
                  </div>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ paddingLeft: "12px", paddingTop: "10px" }}
                >
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Document Number
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="document_number"
                      variant="outlined"
                      fullWidth
                      placeholder="Doc Number"
                      value={formik.values.document_number}
                      // onChange={(event) =>
                      //   formik.setFieldValue(
                      //     "document_number",
                      //     event.target.value
                      //   )
                      // }
                      onChange={(event) => {
                        const value = event.target.value;
                        // Check if the value contains only numbers and has a maximum length of 25 characters
                        if (/^\d{0,25}$/.test(value)) {
                          formik.setFieldValue("document_number", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        (formik.touched.document_number &&
                          formik.errors.document_number) ||
                        ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Expiry Date
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"date"}
                      name="expiry_date"
                      variant="outlined"
                      fullWidth
                      placeholder="Expiry Date"
                      value={formik.values.expiry_date}
                      onBlur={formik.handleBlur}
                      onChange={(event) =>
                        formik.setFieldValue("expiry_date", event.target.value)
                      }
                      inputProps={{
                        min: moment().format("YYYY-MM-DD"),
                      }}
                      helperText={
                        (formik.touched.expiry_date &&
                          formik.errors.expiry_date) ||
                        ""
                      }
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default ProfileSetup;
