import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const Settings = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography className="sub_headings">
                    Manage Transaction Fee
                  </Typography>
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    Transaction Fee
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="transactionFee"
                    variant="outlined"
                    fullWidth
                    placeholder="Transaction Fee"
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4} />
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography className="sub_headings">
                    Manage Transfer Savings
                  </Typography>
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    Date of Transfer
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Date of Transfer"
                    fullWidth
                    name="dateOfTransfer"
                    variant="outlined"
                    type={"date"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="sub_headings">
                    Enable/Disable Apis
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  sx={{ paddingTop: "10px" }}
                >
                  <Box className="switch_flex">
                    <Typography className="custom_label">ACHQ</Typography>
                    <Switch {...label} defaultChecked size="small" />
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  sx={{ paddingTop: "10px" }}
                >
                  <Box className="switch_flex">
                    <Typography className="custom_label">Plaid</Typography>
                    <Switch {...label} defaultChecked size="small" />
                  </Box>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Settings;
