import { useEffect, useState } from "react";
import "./Login.scss";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { usePostLoginMutation } from "../../services/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Loader,
  getFromStorage,
  removeFromStorage,
  showError,
  showToast,
} from "../../constants";
import { setToStorage, STORAGE_KEYS } from "../../constants";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";

const LogIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(0);
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleClose = () => {
    setshowAlert(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        password: formik.values.password,
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.code === 200) {
          console.log("success");
          showToast("Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");

          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || null,
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          const token = response.data.token;
          console.log(token, "tokkkkkkkkkkkkkkkkk");
          navigate("/dashboard", { replace: true });
        } else {
          showError(response?.message);
        }
      } catch (error: any) {
        // showError(error?.data?.message || "");\
        console.error("this is an eror");
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box bg_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">Log in to Narwhale</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%", zIndex: 1 }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                className="text_field"
                name="email"
                type="email"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>

            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                hiddenLabel
                placeholder="Password"
                fullWidth
                className="text_field"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="remember"
                      checked={formik.values.remember}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label="Remember me"
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgot-password")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // disabled={formik.isSubmitting} // Disable the button while submitting
                // onClick={() => navigate("/dashboard")}
              >
                Sign In
              </Button>
            </Box>
          </form>

          <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
            <Typography className="custom_label">
              Don't have an account?
            </Typography>
            <Typography
              className="custom_label login_txt"
              onClick={() => navigate("/sign-up")}
            >
              Signup
            </Typography>
          </Box>
        </div>
      </Container>
    </Box>
  );
};

export default LogIn;
