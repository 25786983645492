import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";
import { CmsData } from "../types/General";

export interface General {
  cms: CmsData[] | null;
}

const initialState: General = {
  cms: [],
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    resetCms: (state, action: PayloadAction<Pick<General, "cms">>) => {
      state.cms = action.payload.cms;
    },
  },
});
export const { resetCms } = generalSlice.actions;
export const getCms = (state: RootState) => state.general.cms;
export default generalSlice.reducer;
