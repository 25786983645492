import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";
import { SettingRes } from "../types/General";

export interface General {
  key: SettingRes | null;
}

const initialState: General = {
  key: null,
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSetting: (state, action: PayloadAction<Pick<General, "key">>) => {
      state.key = action.payload.key;
    },
  },
});
export const { setSetting } = settingSlice.actions;
export const getSetting = (state: RootState) => state.setting.key;
export default settingSlice.reducer;
