import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showToast } from "../../constants";
import {
  useEditProfileMutation,
  useGetCategoryMutation,
  useLazyGetAllAccountsQuery,
} from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import { UploadMedia } from "../../utils/mediaUpload";
import { showError } from "../../constants";
import { CategoryData } from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getToken } from "../../reducers/authSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { error } from "console";
import { PlaidLink } from "react-plaid-link";

const Profile = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<string>("");
  const [professionType, setProfessionType] = useState<string>("");
  const [getUserAccounts] = useLazyGetAllAccountsQuery();
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [getAllCate] = useGetCategoryMutation();
  const handleProfessionChange = (event: SelectChangeEvent) => {
    setProfessionType(event.target.value as string);
  };
  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState("+965");
  const [editProfile] = useEditProfileMutation();
  const user = useAuth();
  const [profile_picture, setProfilePicture] = useState("");
  const [imageId, setImageId] = useState(0);
  const token = useAppSelector(getToken);
  const [loading, setLoading] = useState(false);

  // for the front and back image
  const [front_side, setFrontSide] = useState(""); // front image
  const [back_side, setBackSide] = useState(""); // back image
  const [frontImageId, setFrontImageId] = useState(0); // for front image
  const [backImageId, setBackImageId] = useState(0); // for back image
  const [bankData, setBankData] = useState<any>([]);
  const [error, setError] = useState(false);

  const getAllUserAccounts = async () => {
    try {
      const response = await getUserAccounts({}).unwrap();
      if (response?.status === 200) {
        // setCategoryData(response?.data || []);
        setBankData(response?.data);
      } else {
        setBankData([]);
      }
    } catch (error) {
      console.error("error in fetching the data");
    }
  };

  const getAllCategories = async () => {
    let body = {
      length: 50,
      start: 1,
      search: "",
    };
    try {
      const response = await getAllCate(body).unwrap();
      if (response?.code === 200) {
        // setCategoryData(response?.data || []);
        setCategoryData(response?.data || []);
      } else {
        setCategoryData([]);
      }
    } catch (error) {
      console.error("error in fetching the data");
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      country_code: user?.country_code,
      phone_no: user?.phone_no,
      profile_picture: imageId,
      vendor_category: professionType,
      front_side: frontImageId,
      back_side: backImageId,
      // document_number: user?.document[0]?.document_number || "",
      document_number: 0,
      expiry_date: user?.document[0]?.expiry_date || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("first name is required"),
      last_name: Yup.string().required("last name is required"),
      phone_no: Yup.string().required("phone is required"),
      document_number: Yup.number().required("document is required"),
      expiry_date: Yup.string().required("date is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        user_details: {
          first_name: formik.values.first_name || "",
          last_name: formik.values.last_name || "",
          email: formik.values.email || "",
          country_code: phoneCode,
          phone_no: formik.values.phone_no || "",
          profile_picture: imageId || null,
          vendor_category: professionType,
        },
        documents: [
          {
            id: user?.document[0].id,
            // id: 0,
            front_side: frontImageId || null,
            back_side: backImageId || null,
            document_number: formik.values.document_number,
            expiry_date: formik.values.expiry_date,
          },
        ],
      };

      try {
        const response = await editProfile(body).unwrap();
        if (response?.code === 200) {
          showToast("profile updated successfully");
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("error in fetching the api");
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue("first_name", user?.first_name);
      formik.setFieldValue("last_name", user?.last_name);
      formik.setFieldValue("email", user?.email);
      formik.setFieldValue("country_code", user?.country_code);
      formik.setFieldValue("phone_no", user?.phone_no);
      formik.setFieldValue("profile_picture", profile_picture);
      formik.setFieldValue(
        "document_number",
        user?.document[0]?.document_number
      );
      formik.setFieldValue("expiry_date", user?.document[0]?.expiry_date);
      setProfilePicture(user?.profile_picture?.media_file_url);
      setProfessionType(user?.vendor_category?.id.toString());
      if (user.profile_picture) {
        setImageId(user.profile_picture.id);
      } else {
        setImageId(0);
      }
      if (user.document[0].front_side) {
        // console.log(
        //   user.document[0].front_side,
        //   "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz11111"
        // );
        setFrontImageId(user.document[0].front_side.id);
      } else {
        setFrontImageId(0);
      }
      if (user.document[0].back_side) {
        setBackImageId(user.document[0].back_side.id);
      } else {
        setBackImageId(0);
      }
      setFrontSide(user?.document[0]?.front_side?.media_file_url);
      setBackSide(user?.document[0]?.back_side?.media_file_url);
    }
  }, [user]);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        formik.setFieldValue("profile_picture", res?.data[0]?.media_file_url); // Set the value in Formik
        setProfilePicture(res?.data[0]?.media_file_url); // Update state
        setImageId(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    }
  };

  const handleUploadFrontImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.code === 200) {
          setLoading(false);
          setFrontSide(res?.data[0]?.media_file_url);
          setFrontImageId(res?.data[0]?.id);
        } else {
          showError(res?.message);
        }
      } else {
        setLoading(false);
        showError("Invalid");
      }
    }
  };

  // this is for the handle the back image
  const handleUploadBackImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.code === 200) {
          setLoading(false);
          setBackSide(res?.data[0]?.media_file_url);
          setBackImageId(res?.data[0]?.id);
        } else {
          showError(res?.message);
        }
      } else {
        setLoading(false);
        // Display an error message for non-image files
        showError("Invalid");
      }
    }
  };

  useEffect(() => {
    if (token) {
      getAllCategories();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getAllUserAccounts();
    }
  }, [token]);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Profile</h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {profile_picture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={profile_picture}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={() => {
                            setProfilePicture("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Typography className="custom_label">
                      Account Status : Pending
                    </Typography>
                  </Grid> */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">First Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="first_name"
                      variant="outlined"
                      fullWidth
                      placeholder="First Name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Last Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="last_name"
                      variant="outlined"
                      fullWidth
                      placeholder="Last Name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.last_name && formik.errors.last_name
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      // value={formik.values.phone_no}
                      onChange={(phone_no, country) =>
                        handleChangePhone(phone_no, country)
                      }
                      onBlur={formik.handleBlur}
                      value={phoneCode + formik.values.phone_no}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Category
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={professionType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleProfessionChange}
                      >
                        <MenuItem disabled>Select</MenuItem>
                        {categoryData
                          ? categoryData?.map((row, index) => (
                              <MenuItem value={row?.id} key={row?.id}>
                                {row?.category_name}
                              </MenuItem>
                            ))
                          : " "}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}></Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      {bankData[0]?.accounts[1]?.mask ? (
                        <Box
                          sx={{
                            backgroundColor: "#2593bd59",
                            borderRadius: 2,
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                            paddingLeft: 2,
                            marginTop: 2,
                            marginBottom: 2,
                          }}
                        >
                          <h3>Account Number</h3>
                          <p>************{bankData[0]?.accounts[1]?.mask}</p>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography className="custom_label">QR Code</Typography>
                      <img
                        src="/static/images/qr.png"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </Box>
                    <Box sx={{ pt: 1 }}>
                      <Button
                        className="btn btn_primary"
                        color="primary"
                        size="large"
                        variant="contained"
                      >
                        Download QR Code
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="heading">
                    <Typography
                      className="custom_label"
                      variant="h5"
                      sx={{ pt: 4 }}
                    >
                      Upload Document
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Front Image
                    </Typography>
                    <div className="upload_document w_50">
                      {front_side ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CancelIcon
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setFrontSide("");
                              }}
                            />
                          </div>

                          <figure>
                            <LazyLoadImage
                              width={"100%"}
                              height={"100%"}
                              alt="photo"
                              src={front_side ? front_side : ""}
                              effect="blur"
                            />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <img src="static/images/icon_upload.svg" alt="" />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleUploadFrontImage(e)}
                            />
                          </div>

                          {error && !front_side ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null}
                        </label>
                      )}
                    </div>
                  </Grid>
                  {/* temp */}
                  <Grid item xs={4}>
                    <Typography className="custom_label">Back Image</Typography>
                    <div className="upload_document w_50">
                      {back_side ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CancelIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setBackSide("");
                              }}
                            />
                          </div>
                          <figure>
                            <LazyLoadImage
                              width={"100%"}
                              height={"100%"}
                              alt="photo"
                              src={back_side ? back_side : ""}
                              effect="blur"
                            />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-back">
                          <div className="inner">
                            <span>
                              <img src="static/images/icon_upload.svg" alt="" />
                            </span>
                            <Input
                              id="icon-button-file-back"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleUploadBackImage(e)}
                            />
                          </div>

                          {/* {!back_side ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null} */}
                        </label>
                      )}
                    </div>
                  </Grid>
                  {/* tt */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    {/* <Typography className="custom_label">
                          Document Number
                        </Typography> */}
                    {/* <TextField
                          className="text_field"
                          hiddenLabel
                          type={"text"}
                          name="document_number"
                          variant="outlined"
                          fullWidth
                          placeholder="Doc Number"
                          value={formik.values.document_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.document_number && formik.errors.document_number
                          }
                        ></TextField> */}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Document Number
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="document_number"
                      variant="outlined"
                      fullWidth
                      placeholder="Doc Number"
                      value={formik.values.document_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // helperText={
                      //   formik.touched.document_number && formik.errors.document_number
                      // }
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Expiry Date
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"date"}
                      name="expiry_date"
                      variant="outlined"
                      fullWidth
                      placeholder="Expiry Date"
                      value={formik.values.expiry_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // helperText={
                      //   formik.touched.expiry_date && formik.errors.expiry_date
                      // }
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default Profile;
