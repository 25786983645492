import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const graphOrderData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Users",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#2593bd",
        backgroundColor: "#2593bd",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(37, 147, 189, 0.2)",
        },
      },
    ],
  };
  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Earnings",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#2593bd",
        backgroundColor: "#2593bd",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(37, 147, 189, 0.2)",
        },
      },
    ],
  };

  const navigate = useNavigate();

  const [studentFilter, setStudentFilter] = useState<string>("monthly");
  const [earningFilter, setEarningFilter] = useState<string>("monthly");

  const handleOrderChange = (event: SelectChangeEvent) => {
    setStudentFilter(event.target.value as string);
  };

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4 className="mn_hdng">120</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manage-courses")}
          >
            <AccountBalanceWalletIcon className="svg_icn" />
            <div>
              <h3>Wallet Balance</h3>
              <h4 className="mn_hdng">20,000</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <PaidIcon className="svg_icn" />
            <div>
              <h3>Total Earnings</h3>
              <h4 className="mn_hdng">10</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <PaidIcon className="svg_icn" />
            <div>
              <h3>Total Withdraw balance</h3>
              <h4 className="mn_hdng">20,000</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Users{" "}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Earnings{" "}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={earningFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleEarningChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
